<template>
  <div class="home-page">
    <Header />
    <MappaInquinanti />
    <div class="v-scroll"></div>
    <div class="home1">
      <v-container>
        <v-row>
          <v-col class="col-md-8 col-12">
            <h3 class="mb-5">In evidenza</h3>
            <v-card class="mx-auto mb-5 shadow">
              <v-card-text>
                <p class="small mb-1">Ottobre 2022</p>
                <h4 class="mb-3">
                  Regione Piemonte, con dgr n.9- 2916 del 26 febbraio 2021, ha
                  adottato una serie di disposizioni straordinarie da attuarsi
                  su gran parte del territorio regionale.
                </h4>
                <div class="text--primary small">
                  <p>
                    Relativamente al tema combustione all'aperto dei residui
                    vegetali, tali disposizioni estendono il periodo di divieto
                    a partire dal 15 di settembre di ogni anno e sino al 15 di
                    aprile dell'anno successivo nelle sole aree oggetto della
                    Procedura d'Infrazione UE (Comuni ricadenti nelle zone
                    IT0118, IT0119 e IT0120, ovvero 947 comuni in totale).
                  </p>
                  <p>
                    Al seguente link sono disponibili le più frequenti
                    Domande&Risposte circa l’applicazione di tali divieti:
                    <a
                      target="_blank"
                      href="https://www.regione.piemonte.it/web/temi/ambiente-territorio/ambiente/aria/applicazione-delle-misure-limitazione-delle-emissioni-che-interessano-combustioni-allaperto"
                      title="Questo link si apre in una nuova scheda"
                      >Domande&Risposte
                    </a>
                  </p>
                </div>
              </v-card-text>
              <v-card-actions>
                <div class="spacer"></div>
                <a
                  target="_blank"
                  href=" https://www.regione.piemonte.it/web/temi/ambiente-territorio/ambiente/aria/applicazione-delle-misure-limitazione-delle-emissioni-che-interessano-combustioni-allaperto"
                  class="v-btn primary"
                  title="Questo link si apre in una nuova scheda"
                >
                  <span class="v-btn__content">
                    SCOPRI DI PIU'
                    <i class="v-icon notranslate mdi mdi-launch"></i>
                  </span>
                </a>
              </v-card-actions>
            </v-card>

            <!-- <v-card class="mx-auto mb-5 shadow">
              <v-card-text>
                <p class="small mb-1">15 Settembre 2021</p>
                <h4 class="mb-3">
                  Disposizioni straordinarie per il miglioramento della qualità
                  dell’aria
                </h4>
                <div class="text--primary small">
                  Dal 15 settembre 2021 al 15 aprile 2022 saranno operative le
                  misure a carattere temporaneo per la tutela della salute dei
                  cittadini che si attivano, con l'accensione del semaforo di
                  qualità dell’aria, al fine di prevenire e contenere i
                  superamenti del valore limite giornaliero di PM10.
                </div>
              </v-card-text>
              <v-card-actions>
                <div class="spacer"></div>
                <a
                  target="_blank"
                  href=" https://www.regione.piemonte.it/web/temi/ambiente-territorio/ambiente/aria/disposizioni-straordinarie-per-miglioramento-della-qualita-dellaria"
                  class="v-btn primary"
                  title="Questo link si apre in una nuova scheda"
                >
                  <span class="v-btn__content">
                    SCOPRI DI PIU'
                    <i class="v-icon notranslate mdi mdi-launch"></i>
                  </span>
                </a>
              </v-card-actions>
            </v-card>
            -->
            <!-- <v-card class="mx-auto mb-5 shadow">
              <v-card-text>
                <p class="small mb-1">11 Giugno 2021</p>
                <div class="text--primary small">
                  <p>
                    <strong>Parliamo di qualità dell’aria</strong> è l'argomento
                    del webinar organizzato il
                    <strong>14 giugno ore 14.30</strong> in cui Arpa e Regione
                    Piemonte discuteranno della qualità dell'aria del nostro
                    territorio.
                  </p>
                  <p>
                    Al termine dell’intervista sarà possibile intervenire, porre
                    domande e partecipare a sondaggi on-line.
                  </p>
                  <p>
                    Per approfondimenti e iscrizioni a questo evento:
                    <a
                      target="_blank"
                      href="http://www.arpa.piemonte.it/arpa-comunica/events/eventi-2021/porte-aperte-allambiente"
                      title="Questo link si apre in una nuova scheda"
                      >Porte aperte all'ambiente
                    </a>
                  </p>
                </div>
              </v-card-text>
              <v-card-actions>
                <div class="spacer"></div>
                <a
                  target="_blank"
                  href="http://www.arpa.piemonte.it/arpa-comunica/events/eventi-2021/porte-aperte-allambiente"
                  class="v-btn primary"
                  title="Questo link si apre in una nuova scheda"
                >
                  <span class="v-btn__content">
                    SCOPRI DI PIU'
                    <i class="v-icon notranslate mdi mdi-launch"></i>
                  </span>
                </a>
              </v-card-actions>
            </v-card>
            -->
            <!--v-card class="mx-auto mb-5 shadow">
              <v-card-text>
                <p class="small mb-1">5 Marzo 2021</p>
                <h4 class="mb-3">
                  Disposizioni straordinarie per la qualità dell’aria
                </h4>
                <div class="text--primary small">
                  La Regione Piemonte, in accordo con le altre
                  <strong>Regioni del bacino Padano</strong>, ha adottato
                  disposizioni straordinarie in materia di qualità dell’aria, al
                  fine di agire sulle principali sorgenti di emissione.
                  <br />
                  Tutte le misure sono attive dal
                  <strong>1 marzo 2021</strong> e riguardano tutto il territorio
                  piemontese.<br />
                </div>
              </v-card-text>
              <v-card-actions>
                <div class="spacer"></div>
                <a
                  target="_blank"
                  href="https://www.regione.piemonte.it/web/temi/ambiente-territorio/ambiente/aria/disposizioni-straordinarie-per-qualita-dellaria"
                  class="v-btn primary"
                  title="Questo link si apre in una nuova scheda"
                >
                  <span class="v-btn__content">
                    SCOPRI DI PIU'
                    <i class="v-icon notranslate mdi mdi-launch"></i>
                  </span>
                </a>
              </v-card-actions>
            </!--v-card-->
            <!--v-card class="mx-auto mb-5 shadow">
              <v-card-text>
                <p class="small mb-1">19 Giugno 2020</p>
                <h4 class="mb-3">
                  Uno studio dei partner del progetto LIFE Prepair
                </h4>
                <div class="text--primary small">
                  Oggi venerdì 19 Giugno 2020 in diretta attraverso il canale
                  YouTube di progetto sono stati presentati i risultati delle
                  Studio preliminare degli effetti delle misure COVID-19 sulle
                  emissioni in atmosfera.
                </div>
              </v-card-text>
              <v-card-actions>
                <div class="spacer"></div>
                <a
                  target="_blank"
                  href="https://www.lifeprepair.eu/index.php/2020/06/15/covid-19-e-qualita-dellaria-nel-bacino-padano-presentazione-in-diretta-del-rapporto-del-progetto-prepair/"
                  class="v-btn primary"
                  title="Questo link si apre in una nuova scheda"
                >
                  <span class="v-btn__content">
                    SCOPRI DI PIU'
                    <i class="v-icon notranslate mdi mdi-launch"></i>
                  </span>
                </a>
              </v-card-actions>
            </!--v-card-->
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col class="col-md-8 col-12 mt-0 pt-0">
            <h2 class="mt-0 mb-5">Qualità dell'aria</h2>
            <p class="t26">
              L'inquinamento atmosferico modifica le caratteristiche naturali
              dell'atmosfera terrestre, creando
              <strong>problemi di salute</strong> con decessi prematuri per
              l'uomo, <strong>cattive condizioni di vita</strong> per animali e
              piante e cambiamenti climatici.
            </p>
            <p class="t26">
              È <strong>tempo di agire</strong> per prevenire ulteriori danni.
            </p>
            <p>
              <a
                target="_blank"
                href="http://relazione.ambiente.piemonte.it/"
                class="v-btn primary"
                title="Questo link si apre in una nuova scheda"
              >
                <span class="v-btn__content">
                  RAPPORTO STATO DELL'AMBIENTE
                  <i class="v-icon notranslate mdi mdi-launch"></i>
                </span>
              </a>
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="home2"></div>
    <div class="home3">
      <v-container>
        <div class="card-deck">
          <v-card class="mx-auto">
            <v-card-text>
              <h4 class="co2">Inquinanti</h4>
              <div class="text--primary">
                <p>Quali sono i principali inquinanti dell’atmosfera?</p>
                <p>
                  Gli inquinanti solitamente inclusi nella definizione degli
                  indici di qualità dell’aria sono quelli che hanno effetti a
                  breve termine: monossido di <strong>carbonio</strong> (CO),
                  <strong>biossido di azoto</strong> (NO<sub>2</sub>),
                  <strong>ozono</strong> (O<sub>3</sub>),
                  <strong>biossido di zolfo</strong> (SO<sub>2</sub>),
                  <strong>polveri</strong> (PM<sub>10</sub> o PM<sub>2.5</sub>).
                  La normativa identifica i limiti in aria ambiente per ogni
                  singolo inquinante che devono essere rilevati all’aperto,
                  fuori dai luoghi di lavoro.
                </p>
              </div>
            </v-card-text>
            <v-card-actions>
              <div class="spacer"></div>
              <v-btn
                class="primary"
                :to="{ name: 'inquinanti' }"
                aria-label="Maggiori info sugli inquinanti"
              >
                MAGGIORI INFO
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card class="mx-auto">
            <v-card-text>
              <h4 class="employee">Effetti sulla salute e sull'ambiente</h4>
              <div class="text--primary">
                <p>Perché gli inquinanti sono dannosi?</p>
                <p>
                  L’inquinamento atmosferico è stato classificato tra i
                  cancerogeni umani dall’OMS e i suoi effetti sono
                  particolarmente rilevanti in ambito urbano.
                </p>
                <p>
                  Le principali preoccupazioni riguardano il
                  <strong>particolato</strong> (PM), il
                  <strong>biossido di azoto</strong> (NO<sub>2</sub>) e
                  l’<strong>ozono</strong>
                  (O<sub>3</sub>).
                </p>
              </div>
            </v-card-text>
            <v-card-actions>
              <div class="spacer"></div>
              <v-btn
                class="primary"
                :to="{ name: 'salute' }"
                aria-label="Maggiori info sugli effetti sulla salute e sull'ambiente"
              >
                MAGGIORI INFO
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card class="mx-auto">
            <v-card-text>
              <h4 class="emission">Fonti emissive</h4>
              <div class="text--primary">
                <p>Quali sono le fonti emissive degli inquinanti?</p>
                <p>
                  La lotta all’inquinamento atmosferico è una sfida complessa:
                  gli inquinanti provengono da <strong>più fonti</strong>;
                  colpiscono la salute e l’ambiente, sia a livello locale che
                  globale, ignorando i confini geografici.
                </p>
              </div>
            </v-card-text>
            <v-card-actions>
              <div class="spacer"></div>
              <v-btn
                class="primary"
                :to="{ name: 'fonti' }"
                aria-label="Maggiori info sulle fonti emissive"
              >
                MAGGIORI INFO
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-container>
    </div>
    <div class="home-dati-misurati">
      <v-container>
        <v-row>
          <v-col cols="md-8">
            <h3>Accesso ai dati misurati</h3>
            <p>
              Controlla in tempo reale le informazioni rilevate dalla Rete di
              monitoraggio, per avere tutti i dati sulla tua zona di interesse,
              suddivise per stazione di rilevamento e tipologia inquinante
            </p>
            <p>
              <!--a
                href="/qualita-aria/dati"
                aria-current="page"
                class="v-btn--active v-btn v-btn--depressed v-btn--flat v-btn--outlined v-btn--router theme--light v-size--default"
                ><span class="v-btn__content">SCOPRI DI PIU'</span></a
              -->
              <router-link
                :to="{ name: 'dati-qualita-aria' }"
                aria-current="page"
                class="v-btn--active v-btn v-btn--depressed v-btn--flat v-btn--outlined v-btn--router theme--light v-size--default"
              >
                <span class="v-btn__content">SCOPRI DI PIU'</span>
              </router-link>
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="home4">
      <v-container>
        <h2>Valutare l'aria</h2>
        <v-row justify-center>
          <v-col offset="0" cols="md-8" offset-md="2">
            <p class="t26">
              Valutare l'aria serve a
              <strong>pianificare le azioni per migliorarla</strong>.<br />
              La valutazione è un <strong>ciclo virtuoso</strong> fra la stima
              delle cause (emissioni), la previsione della loro diffusione (la
              modellistica) e la verifica con misurazioni puntuali (la rete di
              rilevamento)
            </p>
          </v-col>
        </v-row>
        <div class="card-deck">
          <v-card outlined class="mx-auto">
            <v-card-text>
              <h4>Inventario emissioni</h4>
              <div class="text--1"></div>
            </v-card-text>
            <div class="text-center">
              <v-btn
                outlined
                :to="{ name: 'emissioni' }"
                aria-label="Maggiori info sull'inventario emissioni"
                >MAGGIORI INFO</v-btn
              >
            </div>
          </v-card>
          <v-card outlined class="mx-auto">
            <v-card-text>
              <h4>Modelli di qualità dell'aria</h4>
              <div class="text--2"></div>
            </v-card-text>
            <div class="text-center">
              <v-btn outlined :to="{ name: 'modelli' }">MAGGIORI INFO</v-btn>
            </div>
          </v-card>
          <v-card outlined class="mx-auto">
            <v-card-text>
              <h4>Rete di rilevamento</h4>
              <div class="text--3"></div>
            </v-card-text>
            <div class="text-center">
              <v-btn
                outlined
                :to="{ name: 'monitoraggio' }"
                aria-label="Maggiori info sulla rete di rilevamento"
              >
                MAGGIORI INFO
              </v-btn>
            </div>
          </v-card>
        </div>
      </v-container>
    </div>
    <div class="home5">
      <v-container>
        <h2>Politiche per l'aria</h2>
        <v-row justify-center>
          <div class="col col-8 offset-2">
            <p class="mt-5">
              L’inquinamento accompagna l’evoluzione della civiltà umana. Negli
              ultimi decenni
              <strong>la qualità dell’aria in Piemonte è migliorata</strong>
              significativamente anche grazie a un approccio sistemico e
              integrato delle politiche adottate.
            </p>
            <p class="mt-5">
              Proprio la sinergia delle politiche regionali ha dato vita a uno
              strumento strategico di indirizzo e di azioni, il nuovo
              <strong>Piano Regionale di Qualità dell’Aria</strong>. Il
              miglioramento della qualità dell’aria è perseguito anche
              attraverso <strong>specifici progetti</strong> a valenza nazionale
              ed europea, grazie allo sviluppo di reti di collaborazione e allo
              stimolo al cambiamento degli <strong>stili di vita</strong>.
            </p>
            <p class="f20">
              <strong>Attività con maggiore impatto per l'ambiente</strong>
            </p>
          </div>
        </v-row>
        <div class="card-deck">
          <v-card outlined class="mx-auto">
            <v-card-text>
              <div class="trasporti"></div>
            </v-card-text>
            <div class="text-center">TRASPORTI</div>
          </v-card>
          <v-card outlined class="mx-auto">
            <v-card-text>
              <div class="rinnovabile"></div>
            </v-card-text>
            <div class="text-center">ENERGIA</div>
          </v-card>
          <v-card outlined class="mx-auto">
            <v-card-text>
              <div class="industria"></div>
            </v-card-text>
            <div class="text-center">INDUSTRIA</div>
          </v-card>
          <v-card outlined class="mx-auto">
            <v-card-text>
              <div class="riqualificazione"></div>
            </v-card-text>
            <div class="text-center">RIQUALIFICAZIONE URBANA</div>
          </v-card>
          <v-card outlined class="mx-auto">
            <v-card-text>
              <div class="agri"></div>
            </v-card-text>
            <div class="text-center">AGRICOLTURA</div>
          </v-card>
          <v-card outlined class="mx-auto">
            <v-card-text>
              <div class="comunicazione"></div>
            </v-card-text>
            <div class="text-center">COMUNICAZIONE</div>
          </v-card>
        </div>
        <p class="pt-40">
          <v-btn outlined :to="{ name: 'piano' }"> DETTAGLI PRQA </v-btn>
        </p>
      </v-container>
    </div>
    <div>
      <v-container stili>
        <v-row>
          <v-col class="col-md-6 col-12">
            <h3>Inquinamento e stili di vita</h3>
            <p>
              La qualità dell'aria è determinata per il <strong>90%</strong> dai
              nostri comportamenti: uso dell'auto, come consumiamo,
              riscaldamento domestico. Poiché il rischio maggiore per la salute
              e l'ambiente è l'inquinamento atmosferico, occorre uno
              <strong>sforzo comune</strong>
              per limitarlo nel minor tempo possibile.
            </p>
            <p class="small">
              FONTE: ORGANIZZAZIONE MONDIALE DELLA SANITA' (OMS)
            </p>
            <p>
              <v-btn
                outlined
                :to="{ name: 'stili' }"
                aria-label="Maggiori info sull'inquinamento e stili di vita"
                >MAGGIORI INFO</v-btn
              >
            </p>
          </v-col>
          <v-col class="col-md-6 col-12 sdv d-none d-md-block"></v-col>
        </v-row>
      </v-container>
    </div>
    <div class="home-piemonte">
      <v-container>
        <img
          alt="Piemonte verso un presente sostenibile"
          src="../assets/stylesheets/im/logo-piemonte-home.png"
        />
        <!--p>
          <a
            href="#"
            class="v-btn primary"
            title="Questo link si apre in una nuova scheda"
          >
            <span class="v-btn__content">
              SCOPRI DI PIU'
              <i class="v-icon notranslate mdi mdi-launch"></i>
            </span>
          </a>
        </p-->
      </v-container>
    </div>
    <div class="home-approfondimemnti">
      <v-container>
        <v-row>
          <v-col>
            <h4>Approfondimenti esterni</h4>
          </v-col>
        </v-row>
        <v-row>
          <div class="col-sm-12 col-md-4 col-lg-4">
            <v-list-item-content>
              <v-list-item-title>STRUMENTI</v-list-item-title>
              <v-list-item>
                <a
                  target="_blank"
                  href="https://www.regione.piemonte.it/web/temi/ambiente-territorio/ambiente/aria/normativa-aria"
                  title="Questo link si apre in una nuova scheda"
                  >Normativa aria <i class="fas fa-external-link-alt"></i>
                </a>
              </v-list-item>
              <v-list-item
                ><a
                  target="_blank"
                  href="http://www.comune.torino.it/emergenzaambientale/documenti/2019-20/faq_aria.pdf"
                  title="Questo link si apre in una nuova scheda"
                  >Faq Comune di Torino
                  <i class="far fa-file-pdf" aria-hidden="true"></i></a
              ></v-list-item>
              <!--v-list-item
                ><a href="#" title="Questo link si apre in una nuova scheda"
                  >Faq Blocchi traffico Regione Piemonte
                  <i class="far fa-file-pdf" aria-hidden="true"></i></a
              ></!--v-list-item-->
              <v-list-item
                ><a
                  target="_blank"
                  href="http://relazione.ambiente.piemonte.it/2020/it"
                  title="Questo link si apre in una nuova scheda"
                  >Relazione Stato dell'Ambiente
                  <i class="fas fa-external-link-alt" aria-hidden="true"></i></a
              ></v-list-item>
            </v-list-item-content>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4">
            <v-list-item-content>
              <v-list-item-title>SITI ISTITUZIONALI</v-list-item-title>
              <v-list-item
                ><a
                  target="_blank"
                  href="https://ec.europa.eu/environment/air/"
                  title="Questo link si apre in una nuova scheda"
                  >Commissione Europea
                  <i class="fas fa-external-link-alt" aria-hidden="true"></i></a
              ></v-list-item>
              <v-list-item
                ><a
                  target="_blank"
                  href="https://www.minambiente.it/pagina/clima"
                  title="Questo link si apre in una nuova scheda"
                  >Ministero dell'Ambiente
                  <i class="fas fa-external-link-alt" aria-hidden="true"></i></a
              ></v-list-item>
              <v-list-item
                ><a
                  target="_blank"
                  href="https://www.eea.europa.eu/themes/air"
                  title="Questo link si apre in una nuova scheda"
                  >EEA European Environment Agency
                  <i class="fas fa-external-link-alt" aria-hidden="true"></i></a
              ></v-list-item>
              <v-list-item
                ><a
                  target="_blank"
                  href="https://www.isprambiente.gov.it/it"
                  title="Questo link si apre in una nuova scheda"
                  >ISPRA
                  <i class="fas fa-external-link-alt" aria-hidden="true"></i></a
              ></v-list-item>
              <v-list-item
                ><a
                  target="_blank"
                  href="https://www.who.int/health-topics/air-pollution#tab=tab_1"
                  title="Questo link si apre in una nuova scheda"
                  >WHO World Health Organization
                  <i class="fas fa-external-link-alt" aria-hidden="true"></i></a
              ></v-list-item>
            </v-list-item-content>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4">
            <v-list-item-content>
              <v-list-item-title>CONSULTAZIONE DATI</v-list-item-title>
              <v-list-item
                ><a
                  target="_blank"
                  href="https://www.arpa.piemonte.it/bollettini/"
                  title="Questo link si apre in una nuova scheda"
                  >Bollettini ARPA
                  <i class="fas fa-external-link-alt" aria-hidden="true"></i></a
              ></v-list-item>
              <v-list-item
                ><a
                  target="_blank"
                  href="https://webgis.arpa.piemonte.it/protocollo_aria_webapp/"
                  title="Questo link si apre in una nuova scheda"
                  >Semaforo qualità dell'aria
                  <i class="fas fa-external-link-alt" aria-hidden="true"></i></a
              ></v-list-item>
              <v-list-item
                ><a
                  target="_blank"
                  href="https://www.geoportale.piemonte.it/cms/"
                  title="Questo link si apre in una nuova scheda"
                  >Geoportale Regione
                  <i class="fas fa-external-link-alt" aria-hidden="true"></i></a
              ></v-list-item>
              <v-list-item
                ><a
                  target="_blank"
                  href="https://webgis.arpa.piemonte.it/geoportale/index.php/tematiche/aria"
                  title="Questo link si apre in una nuova scheda"
                  >Geoportale ARPA
                  <i class="fas fa-external-link-alt" aria-hidden="true"></i></a
              ></v-list-item>
            </v-list-item-content>
          </div>
        </v-row>
        <v-row>
          <v-col>
            <h3>Partecipano al progetto</h3>
          </v-col>
        </v-row>
        <v-row class="partecipano">
          <v-col>
            <img
              alt="Provincia di Alessandria"
              src="../assets/stylesheets/im/alessandria.png"
            />
            <p class="small">Provincia di Alessandria</p>
          </v-col>
          <v-col>
            <img
              alt="Provincia di Asti"
              src="../assets/stylesheets/im/asti.png"
            />
            <p class="small">Provincia di Asti</p>
          </v-col>
          <v-col>
            <img
              alt="Provincia di Biella"
              src="../assets/stylesheets/im/biella.png"
            />
            <p class="small">Provincia di Biella</p>
          </v-col>
          <v-col>
            <img
              alt="Provincia di Cuneo"
              src="../assets/stylesheets/im/cuneo.png"
            />
            <p class="small">Provincia di Cuneo</p>
          </v-col>
          <v-col>
            <img
              alt="Provincia di Novara"
              src="../assets/stylesheets/im/novara.png"
            />
            <p class="small">Provincia di Novara</p>
          </v-col>
          <v-col>
            <img
              alt="Provincia di Verbano-Cusio-Ossola"
              src="../assets/stylesheets/im/vco.png"
            />
            <p class="small">Provincia di Verbano-Cusio-Ossola</p>
          </v-col>
          <v-col>
            <img
              alt="Provincia di Vercelli"
              src="../assets/stylesheets/im/vercelli.png"
            />
            <p class="small">Provincia di Vercelli</p>
          </v-col>
          <v-col>
            <img
              alt="Città metropolitana di Torino"
              src="../assets/stylesheets/im/cmto.png"
            />
            <p class="small">Città metropolitana di Torino</p>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import MappaInquinanti from "@/components/mappa/MappaInquinanti";
import Header from "@/components/layout/Header";

export default {
  name: "Home",
  components: { MappaInquinanti, Header }
};
</script>
