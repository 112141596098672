import moment from "moment";

let config = {};
let today = new moment().format("YYYY-MM-DD");

config.openStreetMapUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
config.zoom = 8;
config.minZoom = 7;
//config.center = [45.116177, 7.742615];
config.center = [45.2832777, 8.0596798];
//config.center = [45.565, 8.053];
//config.center = [45.622474, 8.045852];

let corner1 = L.latLng(47.51, 6.097);
let corner2 = L.latLng(43.85, 10.426);
let corner1_mobile = L.latLng(46.5116, 6.0301);
let corner2_mobile = L.latLng(44.099, 9.871);
config.bounds = L.latLngBounds(corner1, corner2);
config.boundsMobile = L.latLngBounds(corner1_mobile, corner2_mobile);

const wmsAWUrl =
  process.env.VUE_APP_ARIAWEB_WMS_URL +
  "ws/airdata/rp-01/ariagisogc/wms_ariaweb_modellistica?TIME=";
const wmsAggregazioniAWUrl =
  process.env.VUE_APP_ARIAWEB_WMS_URL +
  "ws/airdata/rp-01/ariagisogc/wms_ariaweb_aggregazioni?TIME=";
const wmsAWTimeSfx = "T00:00:00Z";
const wfsAWUrl =
  process.env.VUE_APP_ARIAWEB_WMS_URL +
  "ws/airdata/rp-01/ariagisogc/wfs_ariaweb?";

config.wfsAriaWebUrl = wfsAWUrl;
config.wmsAriaWebUrl = wmsAWUrl;
config.wmsAriaWebAggregazioniUrl = wmsAggregazioniAWUrl;
config.wmsAriaWebTimeSfx = wmsAWTimeSfx;
config.wmsLayersStime = [
  {
    name: "BdTre",
    baseUrl: process.env.VUE_APP_GEOMAP_BDTRE_LAYER_URL,
    layers: "regp_sfondo_bdtre_epsg3857",
    format: "image/png",
    transparent: true,
    attribution:
      '<a href="https://www.geoportale.piemonte.it/cms/bdtre/bdtre-2" target="_blank">BDTRE</a>  Regione Piemonte, &copy; <a href=" https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> Contributors',
    visible: true
  },
  {
    name: "Qualita_Aria",
    baseUrl: wmsAWUrl + today + wmsAWTimeSfx,
    options: { noRedraw: false },
    layers: "Mod_IPQA",
    format: "image/png",
    transparent: true,
    attribution:
      '<a href="https://www.geoportale.piemonte.it/cms/bdtre/bdtre-2" target="_blank">BDTRE</a>  Regione Piemonte, &copy; <a href=" https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> Contributors',
    visible: true
  },
  {
    name: "Stazioni_O3",
    baseUrl: wmsAWUrl + today + wmsAWTimeSfx,
    options: { noRedraw: false },
    layers: "Mod_O3",
    format: "image/png",
    transparent: true,
    attribution:
      '<a href="https://www.geoportale.piemonte.it/cms/bdtre/bdtre-2" target="_blank">BDTRE</a>  Regione Piemonte, &copy; <a href=" https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> Contributors',
    visible: false
  },
  {
    name: "Stazioni_NO2",
    baseUrl: wmsAWUrl + today + wmsAWTimeSfx,
    options: { noRedraw: false },
    layers: "Mod_NO2",
    format: "image/png",
    transparent: true,
    attribution:
      '<a href="https://www.geoportale.piemonte.it/cms/bdtre/bdtre-2" target="_blank">BDTRE</a>  Regione Piemonte, &copy; <a href=" https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> Contributors',
    visible: false
  },
  {
    name: "Stazioni_PM10",
    baseUrl: wmsAWUrl + today + wmsAWTimeSfx,
    options: { noRedraw: false },
    layers: "Mod_PM10",
    format: "image/png",
    transparent: true,
    attribution:
      '<a href="https://www.geoportale.piemonte.it/cms/bdtre/bdtre-2" target="_blank">BDTRE</a>  Regione Piemonte, &copy; <a href=" https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> Contributors',
    visible: false
  },
  {
    name: "Stazioni_PM2_5",
    baseUrl: wmsAWUrl + today + wmsAWTimeSfx,
    options: { noRedraw: false },
    layers: "Mod_PM2_5",
    format: "image/png",
    transparent: true,
    attribution:
      '<a href="https://www.geoportale.piemonte.it/cms/bdtre/bdtre-2" target="_blank">BDTRE</a>  Regione Piemonte, &copy; <a href=" https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> Contributors',
    visible: false
  }
];

config.wmsLayersAggregazioni = [
  {
    name: "BdTre",
    baseUrl: process.env.VUE_APP_GEOMAP_BDTRE_LAYER_URL,
    layers: "regp_sfondo_bdtre_epsg3857",
    format: "image/png",
    transparent: true,
    attribution:
      '<a href="https://www.geoportale.piemonte.it/cms/bdtre/bdtre-2" target="_blank">BDTRE</a>  Regione Piemonte, &copy; <a href=" https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> Contributors',
    visible: true
  },
  {
    name: "Stazioni_O3_A",
    baseUrl: wmsAggregazioniAWUrl + today + wmsAWTimeSfx,
    options: { noRedraw: false },
    layers: "Stazioni_O3",
    format: "image/png",
    transparent: true,
    attribution:
      '<a href="https://www.geoportale.piemonte.it/cms/bdtre/bdtre-2" target="_blank">BDTRE</a>  Regione Piemonte, &copy; <a href=" https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> Contributors',
    visible: true
  },
  {
    name: "Stazioni_NO2_A",
    baseUrl: wmsAggregazioniAWUrl + today + wmsAWTimeSfx,
    options: { noRedraw: false },
    layers: "Stazioni_NO2",
    format: "image/png",
    transparent: true,
    attribution:
      '<a href="https://www.geoportale.piemonte.it/cms/bdtre/bdtre-2" target="_blank">BDTRE</a>  Regione Piemonte, &copy; <a href=" https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> Contributors',
    visible: false
  },
  {
    name: "Stazioni_PM10_A",
    baseUrl: wmsAggregazioniAWUrl + today + wmsAWTimeSfx,
    options: { noRedraw: false },
    layers: "Stazioni_PM10",
    format: "image/png",
    transparent: true,
    attribution:
      '<a href="https://www.geoportale.piemonte.it/cms/bdtre/bdtre-2" target="_blank">BDTRE</a>  Regione Piemonte, &copy; <a href=" https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> Contributors',
    visible: false
  },
  {
    name: "Stazioni_PM2_5_A",
    baseUrl: wmsAggregazioniAWUrl + today + wmsAWTimeSfx,
    options: { noRedraw: false },
    layers: "Stazioni_PM2_5",
    format: "image/png",
    transparent: true,
    attribution:
      '<a href="https://www.geoportale.piemonte.it/cms/bdtre/bdtre-2" target="_blank">BDTRE</a>  Regione Piemonte, &copy; <a href=" https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> Contributors',
    visible: false
  }
];

export default config;
